.Navigation {
    // top:0;
    align-items: center;
    color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    position: fixed;
    z-index: 1000;
    height: 70px;
    border-radius: 0px 0px 25px 25px;
    background-color: #008bde;
    background-image: linear-gradient(90deg, #008bde 0%, #d800ff 100%);
    overflow: hidden;
    transition: box-shadow 0.35s;

    &.scroll {
        box-shadow: 0px 8px 8px -6px rgba(0,0,0,.5);
        transition: box-shadow 0.35s;
    }


    .leftSide {
        margin-left: 60px;
        flex: 15%;

        a {
            font-weight: bolder;
            font-size: 25px;
        }
    }

    .rightSide {
        .Options {
            margin-right: 60px;

            .compact {
                display: none;
            }
            a {
                color: #fff;
                font-size: 20px;
                margin-left: 30px;

                text-decoration: none;
                display: inline-block;
                position: relative;
                cursor: pointer;
            }
            a:after {
                background: none repeat scroll 0 0 transparent;
                bottom: 0;
                content: "";
                display: block;
                height: 2px;
                left: 50%;
                position: absolute;
                background: #fff;
                transition: width 0.3s ease 0s, left 0.3s ease 0s;
                width: 0;
            }
            a:hover:after {
                width: 100%;
                left: 0;
            }
            @media only screen and (max-width: 890px) {
                a {
                    font-size: 18px;
                }
            }

            @media only screen and (max-width: 830px) {
                .compact {
                    display: flex;
                }
                a {
                    display: none;
                }
            }
        }
    }
}
