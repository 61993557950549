.About {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 40px;

    .descriptionAndPhoto {
        display: flex;
        flex-direction: horizontal;
        justify-content: center;

        @media only screen and (max-width: 700px) {
            flex-flow: column-reverse;
            align-items: center;
        }

        .description {
            width: 400px;
            overflow-wrap: break-word;
            justify-content: center;
            align-items: center;
            align-content: center;
            color: white;

            @media only screen and (max-width: 700px) {
                width: 90%;
            }

            .noPadding {
                padding: 0;
                margin: 0;
            }

            p {
                font-size: 20px;
                @media only screen and (max-width: 700px) {
                    font-size: 18px;
                }
                a {
                    color: rgb(1, 255, 240);
                    font-weight: bold;
                    text-decoration: none;
                }
            }
        }

        .Image {
            top: 0;
            position: relative;
            @media only screen and (max-width: 700px) {
                margin-bottom: 30px;
                margin-top: 0px;
            }
            img {
                width: 80%;
                max-width: 350px;
                border-radius: 30px;
                filter: drop-shadow(20px 20px 0px rgba(50, 49, 102, 0.3));
                margin-left: 30px;
            }
        }
    }
}
