.Intro {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 200px;
    margin-bottom: 100px;
    padding: 40px;
	padding-top: 150px;
    p {
        font-size: 30px;
        color: white;
        width: 1000px;
        @media screen and (max-width: 700px) {
            font-size: 20px;
        }
    }
}