.ProjectCard {
	display: flex;
	flex-direction: row;
	color: white;
	width: 900px;
	background-color: rgba(50, 49, 102, 0.3);
	border-radius: 20px;
	margin-bottom: 25px;
	height: 350px;
	overflow: hidden;
	background-size: cover;

	@media only screen and (max-width: 1000px) {
		width: calc(100% - 50px);
	}

	@media only screen and (min-width: 700px) {
		background-image: none !important;
	}

	@media only screen and (max-width: 700px) {
		height: auto;
	}

	.projectImage {
		width: 35%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: rgba(50, 49, 102, 0.3);
		background-blend-mode: multiply;
		transition: background-color 0.3s;

		&:hover {
			background-color: transparent;
			transition: background-color 0.3s;
		}

		@media only screen and (max-width: 700px) {
			width: 0;
		}
	}

	.projectDescription {
		width: 60%;
		position: relative;
		top: 5px;
		margin-left: 30px;
		justify-content: space-between;
		flex-direction: column;
		display: flex;
		background-size: cover;
		border-radius: 20px;


		@media only screen and (min-width: 700px) {
			background-image: none !important;
		}

		@media only screen and (max-width: 700px) {
			background-color: rgba(50, 49, 102, 0.85);
			backdrop-filter: blur(2px);
			width: 100%;
			margin-left: 0;
			top: 0;
			padding: 20px 20px 0;

			h2 { 
				margin: 0;
			}
		}

		p {
			font-size: 20px;

			@media only screen and (max-width: 700px) {
				font-size: 18px;
			}
		}

		.skillsAndLinks {
			margin-bottom: 30px;

			.skills {
				margin-top: 10px;
				margin-bottom: 5px;
				span {
					color: white;
					display: inline-block;
					background-color: #323166;
					line-height: 1.3;
					margin-right: 5px;
					margin-bottom: 5px;
					border-radius: 10px;
					border: 1px solid white;
					padding: 8px;
					font-size: 0.85rem;
					line-height: 4px;
				}
			}

			.links {
				display: inline-block;
				a {
					text-decoration: none;
					span {
						color: white;
						background-color: #4C2376;
						margin-right: 5px;
						border-radius: 10px;
						border: 1px solid white;
						padding: 6px;
						line-height: 4px;
						font-size: 0.85rem;
					}

					svg {
						position: relative;
						top: 0.35em;
						margin-left: 5px;
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
}
