.App {
	background-color: #008bde;
	background-image: linear-gradient(90deg, #008bde 0%, #d800ff 100%);
	font-family: "Montserrat", sans-serif;
	height: 100%;
	max-width: 100%;
	width: 100vw;

	footer {
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		padding: 20px 20px;
	}
}
