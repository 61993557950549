.ExperienceCard {
    color: white;
    width: 900px;
    background-color: rgba(50, 49, 102, 0.3);
    border-radius: 20px;
    margin-bottom: 25px;
    overflow: hidden;

    @media only screen and (max-width: 1000px) {
        width: calc(100% - 50px);
    }

    .companyAndInfo {
        display: flex;
        flex-direction: row;
        padding: 20px 40px;
        align-items: center;

        @media only screen and (max-width: 550px) {
            flex-direction: column;
            padding: 20px;
        }

        .companyLogo {
            img {
                border-radius: 15px;
                height: 120px;

                @media only screen and (max-width: 550px) {
                    height: 100px;
                }
            }
        }

        .information {
            font-size: 18px;
            margin-left: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                margin: 0 0 10px;

                @media only screen and (max-width: 550px) {
                    font-size: 16px;
                }

                a {
                    text-decoration: none;
                    color: white;
                }
            }

            @media only screen and (max-width: 550px) {
                margin: 15px 0 0;
            }
        }
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;

        .arrow {
            cursor: pointer;
            transition: transform 0.5s ease;

            &.up {
                transform: rotate(180deg);
            }
        }
    }

    .description-wrapper {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out;
    }

    .description {
        padding: 0 40px 20px;
        
        p {
            margin: 0;
        }

        @media only screen and (max-width: 550px) {
            font-size: 14px;
            padding: 0 20px 20px;
        }

        @media only screen and (max-width: 500px) {
            font-size: 13px;
        }

        .location {
            margin-top: 10px;
        }

        .skills {
            display: inline-block;
            margin-top: 10px;
            line-height: 2.3;
            
            span {
                color: white;
                background-color: #7F00FF;
                margin-right: 5px;
                border-radius: 10px;
                border: 1px solid white;
                padding: 3px;
                line-height: 4px;
            }
        }
    }

    &.Expand {
        .description-wrapper {
            max-height: 1000px; // Adjust this value based on your maximum possible content height
        }

        .description {
            transform: translateY(0);
        }
    }
}