h {
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    display: grid;
    grid-template-columns: 75px max-content 75px;
    grid-template-rows: 20px 0;
    grid-gap: 20px;
    justify-content: center;
    margin-bottom: 30px;
    color: white;

    @media only screen and (max-width: 700px) {
        font-size: 20px;
        grid-template-rows: 15px 0;
        grid-template-columns: 50px max-content 50px;
    }
}

h:after,
h:before {
    content: " ";
    display: block;
    border-bottom: 2px solid white;
}
